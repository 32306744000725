<template>
  <div>
    <div class="content-header cols">
      <div>
        <h2>{{ $tfo('external | search') }}</h2>
      </div>
      <div class="content-header-buttons"></div>
    </div>

    <div class="text-right">
      <table-navigation :state="state"></table-navigation>
    </div>

    <puppeteer-search-item :key="`item-${item.id}`" :item="item" v-for="item in state.items"></puppeteer-search-item>

    <div v-if="!state.items.length" class="cols--center mar-v-2">
      <h4>{{ $tf('no_data') }}</h4>
    </div>

    <div class="text-right">
      <table-navigation :state="state"></table-navigation>
    </div>
  </div>
</template>

<script>
import TableNavigation from '@/components/tables/navigation.vue';
import PuppeteerSearchItem from './search.item';

export default {
  name: 'page-search',
  components: { PuppeteerSearchItem, TableNavigation },
  data: function () {
    return {
      model: {
        name: 'search',
        route: 'puppeteer/search',
        permissions: {
          create: 'ffsecurity.add_watchlist'
        }
      }
    };
  },
  computed: {
    state() {
      return this.$store.state.puppeteer_search;
    }
  },
  mounted() {
    this.$store.dispatch(this.state.Action.Get);
  },
  methods: {
    rowClick(item) {
      if (this.$store.state.app.key.ctrl) return;
      this.$router.push({ path: this.getItemLink(item) });
    },
    getItemLink(item) {
      return `/${this.model.route}/` + encodeURIComponent(item.id) + '/';
    },
    activeChange(v, item) {
      this.$store.dispatch(this.state.Action.Update, { id: item.id, active: v }).catch((e) => {
        item.active = !v;
      });
      return false;
    }
  }
};
</script>
